import React from "react"
import styled from "styled-components"

const Paper = styled.div`
    width: 100%;
    margin: 20px auto;
    background-color: white;
    box-shadow: 0px 0px 5px 0px #888;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    background-image: repeating-linear-gradient(white 0px, white 24px, teal 25px);
    text-align: left;
`

const Content = styled.div`
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 16px;
    line-height: 25px;
    font-family: 'caveat', cursive;
    font-size: 19px;
    letter-spacing: 1px;
    word-spacing: 5px;
`

export default function Handwritten({ children }) {
    return(
        <Paper>
            <Content>
                { children }
            </Content>
        </Paper>
    )
}