import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { 
  Block,
  FlexGroup,
  SubBlock,
  H1,
  H2,
  P,
  SlopeBR,
  SlopeTL,
  IconListItem,
  HeroImgWide,
  HeroImgNarrow
} from "../theme/page-elements/elements"
import Layout from "../components/Layout"
import Handwritten from "../components/Paper/Paper"
import styled from "styled-components"

const CardContainer = styled.div`
  max-width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px;
`

export default function Index() {
  const data = useStaticQuery(getImages)

  return (
    <Layout>
      <Block nopaddingBtm>
        <H1>About Equal</H1>
        <FlexGroup icons>
          <IconListItem
           heading="Credentials"
           text="Our services are credible"
           queryRef="credentials"
           to="#credentials"
           />
           <IconListItem 
            heading="Motivation"
            text="Our reason for being"
            queryRef="motivation"
            to="#motivations"
           />
          <IconListItem
           heading="Values"
           text="What we believe is important"
           queryRef="values"
           to="#values"
          />
        </FlexGroup>
      </Block>
      <Block nopaddingTop gradient>
        <HeroImgWide>
          <Img
            fluid={data.kneeTreatment.childImageSharp.fluid}
            style={{ width: '100%', maxHeight: "60vh" }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </HeroImgWide>
        <HeroImgNarrow>
            <Img
              fluid={data.kneeTreatment.childImageSharp.fluid}
              style={{ margin: '1rem', width: '100%'}}
              imgStyle={{ objectFit: 'contain' }}
            />
        </HeroImgNarrow>
      </Block>
      <Block 
       id="credentials"
       alternate
       nopaddingTop>
        <H2>Credentials</H2>
        <P>
          Although Equal doesn't charge, our services are credible. 
          The credentials and experience of our lead speak to the expertise accessible to you:
        </P>
        <FlexGroup icons>
          <IconListItem 
            heading="Data Scientist"
            text="Employed in a leading Australian company."
            queryRef="experienced"
          />
          <IconListItem 
            heading="Former Clinician"
            text="Valuable knowledge of the health landscape."
            queryRef="experienced"
          />
          <IconListItem 
            heading="Master of Analytics"
            text="Thorough data science and analytics knowledge."
            queryRef="certified"
          />
          <IconListItem 
            heading="Master of Physiotherapy"
            text="Knowledge to converse with clinicians."
            queryRef="certified"
          />
          <IconListItem 
            heading="Cloud Certified"
            text="AWS Certified Solutions Architect."
            queryRef="certified"
          />
        </FlexGroup>
      </Block>
      <Block nopaddingBtm
       id="motivations">
       <H2>Motivation</H2>
        <div style={{width: "90%", maxWidth: "800px"}}>
            <Handwritten>
                Our healthcare system is faced with many well documented challenges. 
                From experience, I am a firm believer that technology and analytics can help the system to grow, transform and continue to deliver quality care for every consumer.
                <br />
                <br />
                Although clinical healthcare wasn't my passion, I remain a passionate believer in the ideals of healthcare.
                By providing an accessible introduction, I hope that companies who otherwise would not have had the resources or expertise to consider or introduce technology and analytics driven change 
                will advance care for tomorrow's patients.
                I hope interacting with a former clinician helps to make this introduction accessible to clinicians not versed in talking technology.
                <br />
                <br />
                When practicing as a physiotherapist, many people trusted me with their care when they were most vulnerable.
                Sharing my view of the world and expertise is a small token of my appreciation to those who trusted me.
                <br />
                <br />
                Damien, Equal Lead
            </Handwritten>
        </div>
      </Block>
      <Block gradient nopaddingTop nopaddingBtm>
        <HeroImgWide>
          <Img
            fluid={data.walkingPractice.childImageSharp.fluid}
            style={{ width: '100%', maxHeight: "60vh" }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </HeroImgWide>
        <HeroImgNarrow>
            <Img
              fluid={data.walkingPractice.childImageSharp.fluid}
              style={{ margin: '1rem', width: '100%'}}
              imgStyle={{ objectFit: 'contain' }}
            />
        </HeroImgNarrow>
      </Block>
      <Block alternate nopaddingTop
       id="values">
        <H2>Values</H2>
        <P>
          Healthcare is a long standing pillar of society.
          While introducing change, technology should not degrade the important human aspects of care
        </P>
        <FlexGroup>
          <IconListItem 
            heading="Equal Partnerships"
            text="Partnerships between clinician and patient are the heart of healthcare."
            queryRef="partnership"
          />
          <CardContainer>
            <Img
              fluid={data.plus.childImageSharp.fluid}
              style={{ width: '50px'}}
              imgStyle={{ objectFit: 'contain' }}
            />
          </CardContainer>
          <IconListItem 
            heading="Electronic Quality"
            text="True e-Quality embeds ease of use, scalability and governance."
            queryRef="eQuality"
          />
          <CardContainer>
            <Img
              fluid={data.plus.childImageSharp.fluid}
              style={{ width: '50px'}}
              imgStyle={{ objectFit: 'contain' }}
            />
          </CardContainer>
          <IconListItem 
            heading="Insights = Innovation"
            text="Innovation starts with an insight, either from people or data."
            queryRef="insights"
          />
        </FlexGroup>
      </Block>
    </Layout>
  )
}

export const getImages = graphql`
  query {
    elderlyRehab: file(relativePath: { eq: "elderly-rehab.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    oldieExercise: file(relativePath: { eq: "oldie-exercise.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    walkingPractice: file(relativePath: { eq: "walking-practice.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    kneeTreatment: file(relativePath: { eq: "knee-treatment.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    notebook: allJupyterNotebook {
      edges {
        node {
          fileAbsolutePath
          html
        }
      }
    },
    plus: file(relativePath: { eq: "plus.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
  `
